@font-face {
  font-family: "AvenirNext";
  src: url(./assets/fonts/Avenir-Next-Font/AvenirNextLTPro-Bold.otf);
  font-weight: 700; /* Use standard font-weight values (bold = 700) */
}
@font-face {
  font-family: "AvenirNext";
  src: url(./assets/fonts/Avenir-Next-Font/AvenirNextLTPro-Demi.otf);
  font-weight: 600;
}

@font-face {
  font-family: "AvenirNext";
  src: url(./assets/fonts/Avenir-Next-Font/AvenirNextLTPro-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "AvenirNext";
  src: url(./assets/fonts/Avenir-Next-Font/AvenirNextLTPro-Regular.otf);
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNext";
  src: url(./assets/fonts/Avenir-Next-Font/AvenirNextLTPro-Regular.otf);
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DM-Sans-Font/DMSans-Regular.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DM-Sans-Font/DMSans-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "DM Sans";
  src: url(./assets/fonts/DM-Sans-Font/DMSans-Bold.ttf);
  font-weight: 700;
}

.DraftEditor-editorContainer {
  min-height: 200px !important;
}
.textarea-min-height {
  border-radius: 4px;
  border-color: #b5b5b5;
}
.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
  height: 100%!important;
}

.ButtonGroup__root___3lEAn {
  z-index: 0;
  position: relative;
}

.marker{
  border: 1px solid rgb(131, 131, 131);
  border-radius: 50px;
}

.slider {
  height: 238px!important;
}

.MuiDataGrid-cell:focus-within{
  outline: none !important;
}

.pac-target-input:not(:-webkit-autofill) {
  animation-name: endBrowserAutofill;
  width: 100%;
  font-size: 15px;
  height: 33px;
  outline: none;
  border: none;
  border-bottom: 2px solid lightgrey;
}

/* Task Details Modal File Upload Anywhere Style Override **/
.fileUploadArea.is-disabled {
  height: 140px;
  z-index: 999;
  width: auto;
  border: 1px solid rgba(0, 0, 0, 0.23)!important;
  border-radius: 4px;
  cursor: default !important;
}