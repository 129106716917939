body {
  margin: 0;
  font-family: 'Roboto';
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  font-family: "AvenirNext" !important;
  font-family: 'Roboto' !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

body::placeholder {
  color: #ccc !important;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: #ccc;
}

body::-webkit-scrollbar-thumb {
  background: rgba(54, 131, 188, 0.6);
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(54, 131, 188, 0.9);
}
.text-danger{
  color: red;
}