.agent-roster-detail-box {
  width: 100%;
  padding: 10px;
  margin-top: 130px;
  border-radius: 5px;
  font-family: Roboto;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
}
.agent-roster-heading {
  font-weight: 500;
  margin-top: 130px !important;
}
.agentImg {
  width: 40px;
  height: 40px;
}
.agentName {
  display: block;
  font-size: 16px;
  margin-left: 10px;
}
.agent-table-row-heading {
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  font-family: AvenirNext;
  line-height: 1 !important;
  border-left: 2px solid silver;
}
