

/*
 * Property styles in unhighlighted state.
 */
.agent_detail {
  align-items: center;
  background-color: transparent !important;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
}

.agent_detail::after {
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}

.agent_detail .icon {  
  display: none;
  width: 90px;
  height: 90px;
}

.agent_detail .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.agent_detail .address {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin-bottom: 2px;
  margin-top: 15px;
}



/*
 * Property styles in highlighted state.
 */
.agent_detail.highlight .icon {
  display: block;
}
.agent_detail.highlight .price-tag {
  display: none;
}
.agent_detail.highlight {
  background-color: #FFFFFF !important;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: 120px;
  padding: 8px 15px;
  width: auto;
  font-family: AvenirNext !important;
  font-weight: 500;
  font-size: 18px;
  bottom: -10px;
}

.agent_detail.highlight::after {
  border-top: 9px solid #FFFFFF;
}

.agent_detail.highlight .details {
  display: flex;
}

.agent_detail.highlight .details .price-date {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  gap: 20px;
  align-items: center;
}

.agent_detail.highlight .details .price-date .price {
  color: rgba(0, 0, 0, 0.87);
}

.agent_detail.highlight .details .price-date .date {
  color: rgba(0, 0, 0, 0.87);
}

.agent_detail.highlight .icon img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.agent_detail.highlight .features div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 25px; 
  gap: 8px;
}

.agent_detail .bed span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 4px;
}

.agent_detail .bath span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 4px;
}

.agent_detail .home span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 4px;
}

/* price tag marker */
.price-tag {
  border-radius: 8px;
  color: #FFFFFF;
  padding: 10px 15px;
  position: relative;
  font-family: AvenirNext !important;
  font-weight: 600;
  font-size: 13px;
}

.price-tag .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  position: absolute;
  top: -7px;
  right: -7px;
}

.price .icon img {
  width: 16px;
  height: 16px;
}

/* checkbox style */
/* The container */
.select-box {
  position: absolute;
  right: 10px;
  top: 8px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 12px;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input[value="checked"] ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input[value="checked"] ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 3.5px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/** hide info window **/
.gm-style .gm-style-iw-tc, .gm-style .gm-style-iw{
  visibility: hidden;
  width: 328px;
  height: 60px;
}

.gm-style .gm-style-iw{
  visibility: hidden;
}